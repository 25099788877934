import { reactive } from 'vue';
import mitt from 'mitt';
import axios from 'axios';
import script from '@/assets/callscript.json';
import curbsidescript from '@/assets/curbsidecallscript.json';

// for callscript form
export const scriptState = reactive({
    invoiceData: false,
    towInvoice: false,
    fieldsValid: true,
    formData: {
        serviceAddress: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        market: '',
        googleAddress: '',
        latitude: '',
        longitude: '',
        selectedServices: [],
        vehicleYear: '',
        vehicleMake: '',
        vehicleModel: '',
        vehicleColor: '',
        motoristFirstName: '',
        motoristLastName: '',
        motoristCell: '',
        motoristEmail: '',
        highwayCall: '',
        comment: '',
        provider: '',
    },
    towData: {
        startLoc: '',
        startLat: '',
        startLon: '',
        city: '',
        state: '',
        street: '',
        market: '',
        locZip: '',
        destLoc: '',
        destLat: '',
        destLon: '',
        svcList: [],
        provDist: null,
        totalDist: '',
        towCost: null,
        provID: '',
        locValidating: false,
        locValid: null,
        destValidating: false,
        destValid: null,
        towNotes: [],
    },
    nodeHistory: ["1"],
    vehicleData: [],
    validZip: null,
    validVeh: null,
    validSvc: null,
    isZipValidating: false,
    zipValidationResult: null,
    isResValidating: false,
    isResValid: null,
    isSubmitted: false,
    responseMessage: '',
    scheduledTime: '',
    selectedSide: [],
    hwySide: ''
});

export function resetScriptState() {
    scriptState.invoiceData = false;
    scriptState.towInvoice = false;
    scriptState.fieldsValid = true;
    scriptState.nodeHistory = ["1"],
    scriptState.formData.serviceAddress = '';
    scriptState.formData.street = '';
    scriptState.formData.city = '';
    scriptState.formData.state = '';
    scriptState.formData.zip = '';
    scriptState.formData.market = '';
    scriptState.formData.googleAddress = '';
    scriptState.formData.latitude = '';
    scriptState.formData.longitude = '';
    scriptState.formData.selectedServices = [];
    scriptState.formData.vehicleYear = '';
    scriptState.formData.vehicleMake = '';
    scriptState.formData.vehicleModel = '';
    scriptState.formData.vehicleColor = '';
    scriptState.formData.motoristFirstName = '';
    scriptState.formData.motoristLastName = '';
    scriptState.formData.motoristCell = '';
    scriptState.formData.motoristEmail = '';
    scriptState.formData.highwayCall = '';
    scriptState.formData.provider = '';
    scriptState.formData.comment = '';
    scriptState.towData.startLoc = '',
    scriptState.towData.startLat = '',
    scriptState.towData.startLon = '',
    scriptState.towData.city = '',
    scriptState.towData.state = '',
    scriptState.towData.street = '',
    scriptState.towData.market = '',
    scriptState.towData.destLoc = '',
    scriptState.towData.destLat = '',
    scriptState.towData.destLon = '',
    scriptState.towData.provDist = null,
    scriptState.towData.towCost = null,
    scriptState.towData.totalDist = '',
    scriptState.towData.provID = '',
    scriptState.towData.locZip = '',
    scriptState.towData.svcList = [],
    scriptState.towData.locValidating = false,
    scriptState.towData.locValid = null,
    scriptState.towData.destValidating = false,
    scriptState.towData.destValid = null,
    scriptState.isZipValidating = false;
    scriptState.zipValidationResult = null;
    scriptState.vehicleData = [];
    scriptState.validZip = null;
    scriptState.validVeh = null;
    scriptState.validSvc = null;
    scriptState.isResValidating = false;
    scriptState.isResValid = null;
    scriptState.isSubmitted = false;
    scriptState.responseMessage = '';
    scriptState.scheduledTime = '';
    scriptState.selectedSide = [];
    scriptState.hwySide = '';
}

export const curbsidecurrent = reactive({
    currentCurbsideDialogue: curbsidescript.nodes[0],
  });

export const current = reactive({
    currentDialogue: script.nodes[0],
  });



export const fetchPrice = async () => {
    const payload = {
        make: scriptState.formData.vehicleMake,
        model: scriptState.formData.vehicleModel,
        year: scriptState.formData.vehicleYear,
        color: scriptState.formData.vehicleColor,
        services: scriptState.formData.selectedServices,
        street: scriptState.formData.street,
        city: scriptState.formData.city,
        state: scriptState.formData.state,
        zip: scriptState.formData.zip,
        market: scriptState.formData.market,
        motoristFirst: scriptState.formData.motoristFirstName,
        motoristLast: scriptState.formData.motoristLastName,
        motoristCell: scriptState.formData.motoristCell,
        motoristEmail: scriptState.formData.motoristEmail,
        highway: scriptState.formData.highwayCall,
        googleAddress: scriptState.formData.googleAddress,
        latitude: scriptState.formData.latitude,
        longitude: scriptState.formData.longitude,
        comment: scriptState.formData.comment,
        provider: scriptState.formData.provider,
    };
    try {
        const response = await axios.post('/public/price', payload);
        if (response.data.valid) {
            const price = (response.data.amount / 100).toFixed(2);
            return `$${price}`;
        } else {
            return "<error fetching price>";
        }
    } catch (error) {
        console.error("Error fetching price:", error);
        return "<error fetching price>";
    }
};

export const fetchTowPrice = async () => {
    const payload = {
        startLoc: scriptState.towData.startLoc,
        destLoc: scriptState.towData.destLoc,
        svcList: scriptState.towData.svcList,
        zip: scriptState.towData.locZip,
    };
    try {
        const response = await axios.post('/tow/price', payload);
        if (response.data.valid) {
            scriptState.towData.provDist = response.data.providerDistance;
            scriptState.towData.totalDist = response.data.totalDistance;
            scriptState.towData.towCost = response.data.cost;
            scriptState.towData.provID = response.data.provider;
            const price = (response.data.cost / 100).toFixed(2);
            const hold = (price * 1.75).toFixed(2);
            return [`$${price}`,`$${hold}`];
        } else {
            return ["<error fetching price>","<error fetching price>"];
        }
    } catch (error) {
        console.error("Error fetching price:", error);
        return ["<error fetching price>","<error fetching price>"];
    }
};

let webSocketURL;
if (process.env.NODE_ENV === 'development') {
    webSocketURL = 'ws://localhost:3000/ws';
} else {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    webSocketURL = `${protocol}//${window.location.host}/ws`;
}

let webSocketInstance = null;
const eventBus = mitt();

function initializeWebSocket() {
    if (!webSocketInstance || webSocketInstance.readyState === WebSocket.CLOSED) {
        webSocketInstance = new WebSocket(webSocketURL);
        webSocketInstance.addEventListener('open', onWebSocketOpen);
        webSocketInstance.addEventListener('close', onWebSocketClose);
        webSocketInstance.addEventListener('error', onWebSocketError);
        webSocketInstance.onmessage = onWebSocketMessage;
    }
}

function connectWebSocket() {
    initializeWebSocket();
}

function onWebSocketOpen() {
    console.log('WebSocket connection opened');
}

function sendWebSocketMessage(content) {
    if (!webSocketInstance || webSocketInstance.readyState !== WebSocket.OPEN) {
        console.error("WebSocket is not open. Cannot send message.");
        return;
    }
    webSocketInstance.send(content);
}

function isWebSocketConnected() {
    return webSocketInstance && webSocketInstance.readyState === WebSocket.OPEN;
}

function onWebSocketClose() {
    console.log('WebSocket disconnected');
    setTimeout(() => {
        initializeWebSocket();
    }, 2000);
}

function onWebSocketError() {
    console.log('WebSocket error');
    eventBus.emit('error', '');
}

function onWebSocketMessage(event) {
    const data = JSON.parse(event.data);
    eventBus.emit('messageReceived', data);
}

function getWebSocket() {
    return webSocketInstance;
}

export const webSocketAPI = {
    connectWebSocket,
    on: eventBus.on,
    off: eventBus.off,
    send: sendWebSocketMessage,
    isConnected: isWebSocketConnected,
    getWebSocket,
};