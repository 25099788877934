<template>
  <div class="form-container">
    <h1>Additional Comp</h1>
    <hr />
    <form @submit.prevent="submitForm" class="form-group">
      <div class="input-group">
        <input v-model="newEntry.wo" id="wo" placeholder="WO" required />
      </div>
      <button type="button" @click="lookupWo">Fetch</button>
      <div class="input-group">
        <input v-model="techNameInput" @input="filterTechNames" id="techname" list="techname-list" placeholder="Tech name" required />
        <datalist id="techname-list">
          <option v-for="tech in filteredTechs" :key="tech.tech_id" :value="techDisplayName(tech)" />
        </datalist>
      </div>
      <div class="input-group">
        <input v-model="newEntry.techid" id="techid" placeholder="Tech ID" />
      </div>
      <div class="input-group">
        <select v-model="newEntry.description" id="description" placeholder="Description" required>
          <option value="Distance">Distance</option>
          <option value="Priority">Priority</option>
          <option value="Relocation">Relocation</option>
        </select>
      </div>
      <div class="input-group">
        <input v-model="newEntry.amount" id="amount" type="text" placeholder="Amount" @input="formatAmount" required />
      </div>
      <button type="submit">Submit</button>
    </form>
    <hr />
    <div v-if="error" class="error">{{ error }}</div>
    <table>
      <thead>
        <tr>
          <th>WO</th>
          <th>Tech</th>
          <th>Tech ID</th>
          <th>Description</th>
          <th>Submitted</th>
          <th>Amount</th>
          <th>Timestamp</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="event in filteredEvents" :key="event.id">
          <td><a :href="'https://gate.ars-janus.com/workOrder/detail/'+event.wo+'/overview'">{{ event.wo }}</a></td>
          <td>{{ event.tech_name }}</td>
          <td><a :href="'https://gate.ars-janus.com/technician/detail/'+event.tech_id+'/overview'">{{ event.tech_id }}</a></td>
          <td>{{ event.description }}</td>
          <td>{{ event.sender }}</td>
          <td>${{ (event.amount / 100).toFixed(2) }}</td>
          <td>{{ formatToLocalTime(event.timestamp) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EventManagement',
  data() {
    return {
      events: [],
      techs: [],
      newEntry: {
        wo: '',
        techid: '',
        description: '',
        amount: ''
      },
      techNameInput: '',
      filters: {
        startDate: '',
        endDate: ''
      },
      error: ''
    };
  },
computed: {
  filteredEvents() {
    let filteredEvents = [...this.events];

    if (this.filters.startDate && this.filters.endDate) {
      const startDate = new Date(this.filters.startDate);
      const endDate = new Date(this.filters.endDate);
      endDate.setHours(23, 59, 59, 999);

      filteredEvents = filteredEvents.filter(event => {
        const eventDate = new Date(event.timestamp);
        return eventDate >= startDate && eventDate <= endDate;
      });
    }

    return filteredEvents.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  },
    filteredTechs() {
      if (!this.techNameInput) return this.techs;
      const input = this.techNameInput.toLowerCase();
      return this.techs.filter(tech => this.techDisplayName(tech).toLowerCase().includes(input));
    }
  },
  created() {
    this.fetchEvents();
    this.fetchTechs();
  },
  methods: {
    async lookupWo() {
      try {
        const res = await axios.get(`/addon/wo?wo=${this.newEntry.wo}&details=full`);
        this.techNameInput = `${res.data.data.orderDetail.driverName}`;
        this.filterTechNames();
      } catch (error) {
        console.error('Error fetching wo:', error);
        this.error = 'Error getting WO: '+error;
      }
    },
    async fetchEvents() {
      try {
        const response = await axios.get('/comp');
        if (response.data !== null) {
          this.events = response.data;
        } else {
          this.events = [];
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    },
    async fetchTechs() {
      try {
        const response = await axios.get('/list/techs');
        this.techs = response.data;
      } catch (error) {
        console.error('Error fetching techs:', error);
      }
    },
    formatAmount(event) {
      let value = event.target.value;
      value = value.replace(/[^\d.]/g, '');
      const parts = value.split('.');
      if (parts.length > 2) {
        value = parts[0] + '.' + parts.slice(1).join('');
      }
      if (parts[1]?.length > 2) {
        value = `${parts[0]}.${parts[1].slice(0, 2)}`;
      }
      this.newEntry.amount = value;
    },
    filterTechNames() {
      const selectedTech = this.techs.find(tech => this.techDisplayName(tech) === this.techNameInput);
      if (selectedTech) {
        this.newEntry.techid = selectedTech.tech_id;
      } else {
        this.newEntry.techid = '';
      }
    },
    techDisplayName(tech) {
      return `${tech.tech_name}`;
    },
    async submitForm() {
      try {
        const amountInCents = Math.round(parseFloat(this.newEntry.amount) * 100);
        const payload = {
          wo: this.newEntry.wo,
          techId: `${this.newEntry.techid}`,
          description: this.newEntry.description,
          amount: amountInCents
        };
        const response = await axios.post('/comp', payload);
        if (response.status === 200) {
          this.fetchEvents();
          this.resetForm();
        } else {
          this.error = 'Error submiting comp: '+response.data;
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        this.error = error.response?.data?.error || 'Unknown error occurred';
      }
    },
    resetForm() {
      this.newEntry = {
        wo: '',
        techid: '',
        description: '',
        amount: ''
      };
      this.techNameInput = '';
    },
    formatToLocalTime(isoString) {
      const date = new Date(isoString);
      return date.toLocaleString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'
      });
    },
    applyFilters() {
      // other side effects or actions can be added here
    }
  }
}
</script>

<style scoped>
.form-container {
  font-family: 'Nunito', sans-serif;
  font-weight: 200;
  max-width: 80%;
  min-width: 600px;
  background-color: #FAF8F8;
  margin: 20px auto;
  padding: 60px;
  padding-top: 10px;
  border-radius: 8px;
  border: 1px solid #bebebe;
  box-shadow: 0 2px 4px hsla(0, 0%, 62%, 0.714);
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.input-group {
  flex-grow: 1;
  max-width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
}

.input-group label {
  margin-bottom: 5px;
}

.input-group input,
.input-group select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button[type="submit"] {
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #7EB2CF;
  cursor: pointer;
}

button[type="button"] {
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #7EB2CF;
  cursor: pointer;
}

.filter-bar {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.filter-bar label {
  margin-right: 10px;
}

.filter-bar input {
  flex-grow: 1;
  max-width: calc(50% - 20px);
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.filter-bar button {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #fff;
  background-color: #7EB2CF;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

.error {
  color: red;
}
</style>
